import axios from 'axios';
import { authHeader, handleResponse, authHeaderNoRefresh } from '../helpers/auth-header';
import Config from '../config';

const readNotificationRequest = async query => {
  let url = `${Config.apiServerHost}/api/Notification/read`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const viewNotificationRequest = async query => {
  let url = `${Config.apiServerHost}/api/Notification/view`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}

const GetNotificationByUserId = async () => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(`${Config.apiServerHost}/api/Notification`, requestOptions).then(
    handleResponse
  );
};

const handleUpdateActionPlan = async (actionPlan, userId) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify(actionPlan),
  };

  return await fetch(`${Config.apiServerHost}/api/ActionPlan/${userId}`, requestOptions).then(
    handleResponse
  );
};

const getUnreadNotifications = async () => {
  let url = `${Config.apiServerHost}/api/Notification/unread`;
  return await axios.get(url, await authHeader());
};

const dismissAllNotifications = async () => {
  let url = `${Config.apiServerHost}/api/Notification/dismissAll`;
  return await axios.get(url, await authHeader());
};

const getActionPlanById = async id => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(`${Config.apiServerHost}/api/ActionPlan/${id}`, requestOptions).then(
    handleResponse
  );
};

const getUserNotificationsRequest = async () => {
  let url = `${Config.apiServerHost}/api/Notification`;
  return await axios.get(url, await authHeader());
};

const getUnsendNotifications = async () => {
  let url = `${Config.apiServerHost}/api/Notification/unsend`;
  return await axios.get(url, await authHeaderNoRefresh());
};

const updateToSentNotifications = async () => {
  let url = `${Config.apiServerHost}/api/Notification/sent`;
  return await axios.get(url, await authHeaderNoRefresh());
};

export const notificationService = {
  GetNotificationByUserId,
  getActionPlanById,
  handleUpdateActionPlan,
  readNotificationRequest,
  updateToSentNotifications,
  getUserNotificationsRequest,
  getUnsendNotifications,
  getUnreadNotifications,
  dismissAllNotifications,
  viewNotificationRequest,
};
