import { logger } from '../logger';
import { Auth } from 'aws-amplify';
import Config from '../config';
import moment from 'moment';
import axios from 'axios';
import jwt from 'jsonwebtoken';

export const SessionKickOutNote = 'App Forcing User to Sign Out';

export async function postCaseAuditSessionKickOut(token, note) {
  try {
    let tokenVal = token ?? localStorage.getItem('token');
    let url = `${Config.apiServerHost}/api/CaseAudit/session`;
    return await axios.post(
      url,
      { Token: tokenVal, Note: note ?? SessionKickOutNote },
      await authHeader()
    );
  } catch (error) {
    logger.error('Error in postCaseAuditSessionKickOut', error);
  }
}

export async function authHeaderNoRefresh() {
  let token = localStorage.getItem('token');
  if (!token) {
    return await authHeader();
  } else
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
}

export async function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem('token');
  if (token === SessionKickOutNote) {
    return null;
  }
  let oldToken = token;

  try {
    if (token) {
      // Decode the ID token
      const decodedToken = jwt.decode(token);

      // Check expiration time
      const expirationTime = new Date(decodedToken?.exp * 1000);
      const now = new Date();
      // const test = new Date(now.getTime() + 12 * 60000);

      if (expirationTime < now) {
        localStorage.setItem('token', SessionKickOutNote);
        if (oldToken) {
          await postCaseAuditSessionKickOut(oldToken, `${SessionKickOutNote} - Stale Session`);
        }
        localStorage.removeItem('token');
        localStorage.removeItem('AppRegistrationId');
        localStorage.removeItem('AppRegistrationRedirectUrl');
        window.location = '/user/login';
        return null;
      }
    }

    const idToken = (await Auth.currentSession()).getIdToken(); // this automatically refreshes the token when its time
    const newToken = idToken.getJwtToken();

    if (newToken) {
      token = newToken;
      oldToken = oldToken ?? newToken;
      localStorage.setItem('token', newToken);

      try {
        let lastRefreshInSeconds = Number(localStorage.getItem('lastRefresh'));
        const dateInSecs = Math.round(new Date().getTime() / 1000);

        if (
          !lastRefreshInSeconds || //doesnt exist
          lastRefreshInSeconds > dateInSecs || // is somehow in the future
          lastRefreshInSeconds < dateInSecs - 15 // hasnt been called in the last 15 seconds
        ) {
          var signedInAt = idToken.payload.auth_time;
          var issuedAt = idToken.payload.iat;
          var expiresAt = idToken.payload.exp;

          localStorage.setItem(
            'signedInAt',
            moment(signedInAt * 1000).format('DD/MM/YYYY h:mm:ss a')
          );
          localStorage.setItem(
            'signsOffAt',
            moment((signedInAt + 36000) * 1000).format('DD/MM/YYYY h:mm:ss a')
          );
          localStorage.setItem('issuedAt', moment(issuedAt * 1000).format('DD/MM/YYYY h:mm:ss a'));
          localStorage.setItem('expiresAtNum', expiresAt);
          localStorage.setItem(
            'expiresAt',
            moment(expiresAt * 1000).format('DD/MM/YYYY h:mm:ss a')
          );

          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          localStorage.setItem('lastRefresh', dateInSecs);
          cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
            if (session) {
              var refreshedIdToken = session.getIdToken();
              var refreshedJwtToken = refreshedIdToken.getJwtToken();
              if (refreshedJwtToken && refreshedJwtToken != newToken) {
                token = refreshedJwtToken;
                localStorage.setItem('token', refreshedJwtToken);
              }

              signedInAt = refreshedIdToken.payload.auth_time;
              issuedAt = refreshedIdToken.payload.iat;
              expiresAt = refreshedIdToken.payload.exp;

              localStorage.setItem(
                'signedInAt',
                moment(signedInAt * 1000).format('DD/MM/YYYY h:mm:ss a')
              );
              localStorage.setItem(
                'signsOffAt',
                moment((signedInAt + 36000) * 1000).format('DD/MM/YYYY h:mm:ss a')
              );
              localStorage.setItem(
                'issuedAt',
                moment(issuedAt * 1000).format('DD/MM/YYYY h:mm:ss a')
              );
              localStorage.setItem('expiresAtNum', expiresAt);
              localStorage.setItem(
                'expiresAt',
                moment(expiresAt * 1000).format('DD/MM/YYYY h:mm:ss a')
              );
            }
            if (err) {
              logger.error('Refresh Token failed - Logging out user because: ', err);
              if (oldToken) {
                await postCaseAuditSessionKickOut(
                  oldToken,
                  `${SessionKickOutNote} - Refresh Token Error`
                );
              }
              localStorage.setItem('token', null);
              window.location = '/user/login';
              return null;
            }
          });
        }
      } catch (e) {
        logger.debug('Unable to refresh Token', e);
      }
    } else {
      if (oldToken) {
        await postCaseAuditSessionKickOut(idToken, `${SessionKickOutNote} - Refresh Token Failed`);
      }
      return null;
    }
  } catch (error) {
    logger.error('Error refreshing token', error);
    logger.debug('cannot refresh, redirect to login');
    if (
      !window.location.pathname.startsWith('/user') &&
      !window.location.pathname.startsWith('/unsubscribe')
    ) {
      logger.debug('does not start', window.location.pathname.startsWith('/user'));
      if (oldToken) {
        await postCaseAuditSessionKickOut(
          oldToken,
          `${SessionKickOutNote} - Error establishing token`
        );
      }
      window.location = '/user/login';
      return null;
    }
    if (oldToken) {
      await postCaseAuditSessionKickOut(oldToken, `${SessionKickOutNote} - No Token`);
    }
    return null;
  }

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
}

export function handleResponse(response) {
  return response.text().then(text => {
    if (response.status >= 200 && response.status < 300) {
      const data = text && JSON.parse(text);
      return data;
    } else if (response.status === 400) {
      return Promise.reject(JSON.parse(text));
    } else if (response.status === 401) {
      // localStorage.removeItem('token');
      window.location = '/unauthorized';
    } else {
      //const error = response.statusText;
      return Promise.reject(text);
    }
  });
}

export const handleUnauthorized = response => {
  const { status } = response;

  if (status === 401) {
    // localStorage.removeItem('token');
    window.location = '/unauthorized';
  }
};
