import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  TASK_MANGEMENT_ADD_NEW,
  TASK_MANGEMENT_EDIT,
  TASK_MANGEMENT_GET_BY_QUERY,
  TASK_MANGEMENT_GET_BY_ID,
  TASK_MANGEMENT_DELETE,
  TASK_MANGEMENT_STATUS_CHANGE,
} from './types';

import {
  addNewTaskManagementError,
  editTaskManagementError,
  getTaskManagementByQuerySuccess,
  getTaskManagementByQueryError,
  getTaskManagementByIdError,
  getTaskManagementByIdSuccess,
  deleteTaskManagementByIdErrorr,
  changeTaskManagementStatusError,
} from './actions';
import { getNotificationByQuery } from '../actions';

let currentCancelToken;

const addNewTaskManagementReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/ActionPlan`, item, await authHeader());
};

const getTaskManagementByIdsRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/ActionPlan/getByIds`,
    payload,
    await authHeader()
  );
};

const changeTaskManagementStatusRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ActionPlan/statusChange`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const editTaskManagementRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/ActionPlan`, item, await authHeader());
};

const getTaskManagementByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ActionPlan/${id}`, await authHeader());
};

const getTaskCategoryRequest = async payload => {
  if (currentCancelToken) {
    currentCancelToken.cancel('Operation canceled due to new request.');
  }

  // Create a new CancelToken source
  currentCancelToken = axios.CancelToken.source();

  return await axios.post(`${Config.apiServerHost}/api/ActionPlan/taskCategory`, payload, {
    cancelToken: currentCancelToken.token,
    headers: { ...(await authHeader()).headers },
  });
};

const getCustomerSurveyRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/ActionPlan/getCustomerSurvey`,
    payload,
    await authHeader()
  );
};

const getTaskManagementByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/ActionPlan/paging`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteTaskManagementRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ActionPlan/${id}`, await authHeader());
};

function* addNewTaskManagement({ payload }) {
  try {
    let response = yield call(addNewTaskManagementReqeust, payload);
    response = yield call(getTaskManagementByQueryRequest);
    yield put(getTaskManagementByQuerySuccess(response.data));
    yield put(getNotificationByQuery());
  } catch (error) {
    yield put(addNewTaskManagementError(error.response.statusText));
  }
}

function* editTaskManagement({ payload }) {
  try {
    let response = yield call(editTaskManagementRequest, payload);
    response = yield call(getTaskManagementByQueryRequest);
    yield put(getTaskManagementByQuerySuccess(response.data));
  } catch (error) {
    yield put(editTaskManagementError(error.response.statusText));
  }
}

function* getTaskManagementByQuery({ payload }) {
  try {
    const response = yield call(getTaskManagementByQueryRequest, payload);
    yield put(getTaskManagementByQuerySuccess(response.data));
  } catch (error) {
    yield put(getTaskManagementByQueryError(error.response.statusText));
  }
}

function* getTaskManagementById({ payload }) {
  try {
    const response = yield call(getTaskManagementByIdRequest, payload);
    yield put(getTaskManagementByIdSuccess(response.data));
  } catch (error) {
    yield put(getTaskManagementByIdError(error.response.statusText));
  }
}

function* deleteTaskManagement({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteTaskManagementRequest, data);
    response = yield call(getTaskManagementByQueryRequest, query);
    yield put(getTaskManagementByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteTaskManagementByIdErrorr(error.response.statusText));
  }
}

function* changeTaskManagementStatus({ payload }) {
  try {
    let response = yield call(changeTaskManagementStatusRequest, payload);
    response = yield call(getTaskManagementByQueryRequest);
    yield put(getTaskManagementByQuerySuccess(response.data));
    // yield put(changeTaskManagementStatusSuccess(response.data));
  } catch (error) {
    yield put(changeTaskManagementStatusError(error.response.statusText));
  }
}

export function* watchAddNewTaskManagement() {
  yield takeEvery(TASK_MANGEMENT_ADD_NEW, addNewTaskManagement);
}
export function* watchEditTaskManagement() {
  yield takeEvery(TASK_MANGEMENT_EDIT, editTaskManagement);
}

export function* watchGetTaskManagementByQuery() {
  yield takeEvery(TASK_MANGEMENT_GET_BY_QUERY, getTaskManagementByQuery);
}

export function* watchGetTaskManagementById() {
  yield takeEvery(TASK_MANGEMENT_GET_BY_ID, getTaskManagementById);
}

export function* watchDeleteTaskManagement() {
  yield takeEvery(TASK_MANGEMENT_DELETE, deleteTaskManagement);
}

export function* watchChangeTaskManagementStatus() {
  yield takeEvery(TASK_MANGEMENT_STATUS_CHANGE, changeTaskManagementStatus);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewTaskManagement),
    fork(watchEditTaskManagement),
    fork(watchGetTaskManagementByQuery),
    fork(watchDeleteTaskManagement),
    fork(watchGetTaskManagementById),
    fork(watchChangeTaskManagementStatus),
  ]);
}

export {
  getTaskManagementByIdsRequest,
  changeTaskManagementStatusRequest,
  editTaskManagementRequest,
  getTaskManagementByIdRequest,
  getTaskCategoryRequest,
  getCustomerSurveyRequest,
  deleteTaskManagementRequest,
  getTaskManagementByQueryRequest,
};
